<template>
  <div>
    <b-row>
        <b-col>
          <b-card class="custom-modal-card-2 m-0">
            <span class="custom-head-text m-0 custom-font-30 text-blue"
              ><b>Additional Details</b></span
            >
            
            <b-table
              responsive
              class="mt-1 striped adDetail-table custom-font-24"
              :items="mainObject.adDetailsTable"
              striped
              thead-class="d-none"
              :class="{ 'table-sm': !isMobile }"
            >
              <template #cell(key)="row">
                <b>{{ row.item.key }}</b>
              </template>
              
              
              
              
              <template #cell(value)="row" v-if="!isInsightPath">

                <b-button
                  v-if="row.item.key === 'Export' && !isInsightPath"
                  @click="ExportTrx(mainObject._id)"
                  variant="outline-primary"
                  size="sm"
                  class="mr-1"
                >
                  Export
                </b-button>
                <!-- <span v-else>{{ row.item.value }}</span> -->

                <span v-else>
                {{
                  row.item.key.toLowerCase().includes("date") ||
                  row.item.key.toLowerCase().includes("time")
                    ? formatDate(row.item.value)
                    : row.item.value
                }}
              </span>
              </template>

             


            </b-table>
            
          </b-card>
        </b-col>
    </b-row>
  </div>
</template>

<script>
import config from './../../services/globalLit'
export default {
  props: {
    mainObject: {
      type: Object,
      default: () => {},
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      public_service_url: "",
      isInsightPath: false,
    };
  },
  mounted() {
    let host = window.location.host;
    let protocol = window.location.protocol;
    this.public_service_url =
      protocol + "//" + host + "/servicedetail/" + this.mainObject._id;

    this.isInsightPath = window.location.pathname.includes("InsightDetailedPage");
  },
  methods: {
    CopyShareUrl(text) {
      const el = document.createElement("textarea");
      el.addEventListener("focusin", (e) => e.stopPropagation());
      el.value = text;
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
      this.$toasted.show("Copied", {
        theme: "outline",
        position: "bottom-center",
        type: "success",
        duration: 2000,
      });
    },
    async ExportTrx(id) {
      let url = config.exportTrnx + `${id}`;
      const a = document.createElement("a");
      a.href = url;
      a.download = "transaction.pdf";
      document.body.appendChild(a);
      a.click();
      a.remove();
    },

    formatDate(dateString) {
      if (!dateString) return "N/A";
      const date = new Date(dateString);
      return date.toLocaleString("en-US", {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
        hour12: true,
      });
    },
  },
};
</script>

<style>
 .adDetail-table tr {
      -webkit-box-shadow: 0px;
      box-shadow: none;
    }

    .adDetail-table td {
      padding: 0.5rem 0.75rem;
    }
</style>