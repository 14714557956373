import api from "./api";

const userInfo = JSON.parse(localStorage.getItem('userinfo') || '{}');


export default {
  add(data) {
    // return api.execute(`post`, `/moderator/trx`, data);

    return api.execute(`post`, `${userInfo.role === "tester" ? "/tester" : "/moderator"}/trx`, data);
    

  },
  addBasicInfo(data) {
    // return api.execute(`post`, `/moderator/trx/basicInfo`, data);
    return api.execute(`post`, `${userInfo.role === "tester" ? "/tester" : "/moderator"}/trx/basicInfo`, data);

  },
  update(data) {
    // return api.execute(`put`, `/moderator/trx`, data);
    return api.execute(`put`, `${userInfo.role === "tester" ? "/tester" : "/moderator"}/trx`, data);
    
  },
  get(payload) {
    return api.execute("get", `/moderator/trx` + payload);
  },
  getFilteredTrx(payload) {
    let path = `?page=${payload.page || ""}&limit=${
      payload.limit || ""
    }&sortby=${payload.sort || ""}&sortdir=${payload.order || ""}&country=${
      payload.country || ""
    }&nettype=${payload.nettype || ""}`;
    if (payload.unmappedservice) {
      path += `&sid=${payload.unmappedservice}`;
    }
    if (payload.sid) {
      path += `&sid=${payload.sid}`;
    }
    if (payload.trxid) {
      path += `&trxid=${payload.trxid}`;
    }

    if (payload.sdate) {
      path += `&sdate=${payload.sdate}`;
    }
    if (payload.edate) {
      path += `&edate=${payload.edate}`;
    }

    console.log("path", path);
    // return api.execute("get", `/moderator/trx` + path);
    return api.execute(`get`, `${userInfo.role === "tester" ? "/tester" : "/moderator"}/trx` + path);

  },
  remove(data) {
    // return api.execute(`delete`, `/moderator/trx`, data);
    return api.execute(`delete`, `${userInfo.role === "tester" ? "/tester" : "/moderator"}/trx`, data);

  },
  removeLaningImage(data) {
    // return api.execute(`delete`, `/moderator/trx/landing`, data);
    return api.execute(`delete`, `${userInfo.role === "tester" ? "/tester" : "/moderator"}/trx/landing` , data);

  },
  removeBannerImage(data) {
    // return api.execute(`delete`, `/moderator/trx/bannerImage`, data);
    return api.execute(`delete`, `${userInfo.role === "tester" ? "/tester" : "/moderator"}/trx/bannerImage`, data);

  },
  removeAdImage(data) {
    // return api.execute(`delete`, `/moderator/trx/adImage`, data);
    return api.execute(`delete`, `${userInfo.role === "tester" ? "/tester" : "/moderator"}/trx/adImage`, data);

  },
  trxDetail(data) {
    return api.execute(`post`, `/transcation`, data);
  },
  getPublicShareUrl(id) {
    return api.execute(`get`, `/share/url/?trxid=${id}`);
  },
  getLandings(id) {
    return api.execute(`get`, `/image/landing/all/${id}`);
  },
  addCommnet(data) {
    return api.execute(`post`, `/comment`, data);
  },
  getComment(id) {
    return api.execute(`get`, `/comment?trxid=${id}`);
  },
  deleteComment(cid) {
    return api.execute(`delete`, `/comment`, cid);
  },
  editComment(data) {
    return api.execute(`put`, `/comment`, data);
  },
  csvUpload(data) {
    // return api.execute(`post`, `/moderator/trx/upload`, data);
    return api.execute(`post`, `${userInfo.role === "tester" ? "/tester" : "/moderator"}/trx/upload`, data);

  },
  getCounter(data) {
     let queryParams = "";
        for (let key in data) {
          if (data[key]) {
            queryParams += `&${key}=${data[key]}`;
          }
        }
    // return api.execute(`get`, `/moderator/trx/counter?${queryParams}`);
    return api.execute(`get`, `${userInfo.role === "tester" ? "/tester" : "/moderator"}/trx/counter?${queryParams}`);

  },
};
